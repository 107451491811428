const keywords=["The Holidays",
"Wallpapers",
"3D Render",
"Anime",
"Nature",
"Architecture & Interiors",
"Experimental",
"Textures & Patterns",
"Film",
"Animals",
"Street Photography",
"Food & Drink",
"Travel",
"Fashion & Beauty",
"People",
"Health & Wellness",
"Sports"];

export default keywords;